import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import HTMLParser from 'html-react-parser';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { TagCloud } from 'react-tagcloud';

import { constructLink } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import styles from './styles.module.css';
import './cloud.css';

class ArticleMotherLayout extends React.PureComponent {

  static formCounts(data, color) {

    const counts = [];
    data.forEach((item) => {

      if (item.type === 'ARTICLE') {

        item.tags.forEach((tag) => {

          let exists = false;
          counts.some((t, i) => {

            if (t.value === tag) {

              counts[i].count += 1;
              exists = true;

            }

            return exists;

          });

          if (exists === false) {

            counts.push({
              value: tag,
              count: 1,
              color,
            });

          }

        });

      }

    });

    return counts;

  }

  constructor(props) {

    super(props);

    const counts = ArticleMotherLayout.formCounts(props.section.data, props.themeData.colors[0]);
    const index = props.section.data.length - 1;
    const { articleCount } = props.section.data[index];
    const pageCount = Math.ceil((props.section.data.length - 1) / articleCount);
    this.state = {
      section: props.section,
      index,
      articleCount,
      pageCount,
      currentPage: 0,
      searchResults: null,
      counts,
    };

    this.createText = this.createText.bind(this);
    this.createImage = this.createImage.bind(this);
    this.handleStringSearch = this.handleStringSearch.bind(this);
    this.handleDateSearch = this.handleDateSearch.bind(this);
    this.handleTagSearch = this.handleTagSearch.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.createAuthorInfo = this.createAuthorInfo.bind(this);
    this.createSearch = this.createSearch.bind(this);
    this.createArchive = this.createArchive.bind(this);
    this.createTagCloud = this.createTagCloud.bind(this);
    this.createArticles = this.createArticles.bind(this);
    this.createSearchHeader = this.createSearchHeader.bind(this);
    this.createSearchResults = this.createSearchResults.bind(this);
    this.clearSearch = this.clearSearch.bind(this);

  }

  componentWillReceiveProps(nextProps) {

    if (this.props !== nextProps) {

      const counts = ArticleMotherLayout.formCounts(nextProps.section.data, nextProps.themeData.colors[0]);
      const index = nextProps.section.data.length - 1;
      const { articleCount } = nextProps.section.data[index];
      const pageCount = this.state.searchResults !== null
        ? this.state.pageCount : Math.ceil((nextProps.section.data.length - 1) / articleCount);

      this.setState({
        section: nextProps.section,
        index,
        articleCount,
        pageCount,
        searchResults: this.state.searchResults,
        counts,
      });

    }

  }

  createText(item, type, index, subIndex) {

    const id = `${this.state.section._id}_${type.startsWith('TITLE') ? 'Title' : 'Paragraph'}_${subIndex !== undefined ? subIndex : ''}${index}_section`;
    let content;
    let styleNames;
    if (type === 'TITLE') {

      content = `<h3>${item}</h3>`;
      styleNames = styles.articleTitle;

    } else if (type.startsWith('PARAGRAPH')) {

      content = `<span>${item}</span>`;
      styleNames = styles.articleText;

    }

    const text = (
      <div>
        {
          HTMLParser(content)
        }
      </div>
    );

    const elem = (
      <div
        id={id}
        key={id}
        className={styleNames}
      >
        { text }
      </div>
    );

    return elem;

  }

  createImage(item, index, subIndex) {

    let elem;
    if (item.icon !== null || item.id !== '') {

      const wrapper = `articleImageWrapper${item.icon ? 'Icon' : ''}`;
      const imgWrapper = 'imageContent5';
      const img = 'articleImage';

      elem = (
        <div
          className={styles[wrapper]}
          key={`${this.state.section._id}_Image}_${subIndex !== undefined ? subIndex : ''}${index}_section`}
        >
          <SectionMedia
            mediaType={item.icon ? 'ICON' : 'IMAGE'}
            wrapperStyle={imgWrapper}
            elementStyle={img}
            iconStyle=""
            componentIndex={undefined}
            elementIndex={index}
            src={item.CDNLink ? item.CDNLink : `${process.env.IMAGES_CDN}/${item.src}`}
            alt={item.alt}
            data={item}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
          />
        </div>
      );

    }

    return elem;

  }

  handleStringSearch(event) {

    if (event.type === 'mousedown' || (event.type === 'keypress' && event.key === 'Enter')) {

      const articles = [];
      let { value } = event.target;
      if (event.type === 'mousedown') {

        ({ value } = document.getElementById(`${this.state.section._id}_search`));

      }
      const origValue = value;
      value = value.trim().toLowerCase();

      if (value) {

        this.state.section.data.forEach((article, index) => {

          if (
            article.type !== 'STYLE'
            && (
              article.title.toLowerCase().includes(value)
              || article.excerpt.toLowerCase().includes(value)
              || (
                article.author
                && article.author.name
                && article.author.name.toLowerCase().includes(value)
              )
              || article.tags.includes(value)
            )
          ) {

            articles.push(index);

          } else if (article.type !== 'STYLE') {

            article.content.every((cntnt) => {

              let notFound = true;
              if (
                (
                  (cntnt.type === 'PARAGRAPH/NORMAL' || cntnt.type === 'PARAGRAPH/QUOTE' || cntnt.type === 'TITLE')
                  && cntnt.content.toLowerCase().includes(value)
                )
                || (cntnt.type === 'IMAGE' && cntnt.content.alt.toLowerCase().includes(value))
              ) {

                articles.push(index);
                notFound = false;

              }

              return notFound;

            });

          }

        });

        const pageCount = Math.ceil(articles.length / this.state.articleCount);
        this.setState({
          searchResults: {
            type: 'search',
            search: origValue,
            articles,
          },
          pageCount,
          currentPage: 0,
        });

      } else this.clearSearch();

    }

  }

  handleDateSearch(event) {

    const { value } = event.target.options[event.target.selectedIndex].dataset;

    if (value !== 'select') {

      const articles = [];
      this.state.section.data.forEach((article, index) => {

        if (article.type === 'ARTICLE') {

          const pubDate = moment(article.pubDate);

          if (moment(value).isSame(moment(pubDate), 'month')) {

            articles.push(index);

          }

        }

      });

      const pageCount = Math.ceil(articles.length / this.state.articleCount);
      this.setState({
        searchResults: {
          type: 'archive',
          search: value,
          articles,
        },
        pageCount,
        currentPage: 0,
      });

    } else this.clearSearch();

  }

  handleTagSearch(event) {

    const { value } = event.target !== undefined ? event.target.dataset : event;
    let articles = null;
    articles = [];
    this.state.section.data.forEach((article, index) => {

      if (article.type === 'ARTICLE') {

        if (article.tags.includes(value)) {

          articles.push(index);

        }

      }

    });

    const pageCount = Math.ceil(articles.length / this.state.articleCount);
    this.setState({
      searchResults: {
        type: 'tag',
        search: value,
        articles,
      },
      pageCount,
      currentPage: 0,
    });

  }

  handlePageClick(data) {

    const currentPage = data.selected;
    this.setState({
      currentPage,
    });

  }

  createAuthorInfo(data) {

    const author = this.state.section
      .data[this.state.index].authors.find(a => a._id === data.owner);
    let value;
    if (author !== undefined) {

      const links = [];
      if (author.links.length > 0) {

        author.links.forEach((l, i) => {

          if (l.active === true && l.image.icon !== null) {

            const {
              link,
            } = constructLink(l.image.linkUrl, this.props.pageIndex, this.props.articleIndex);

            let tab;
            if (l.image.linkUrl) tab = typeof l.image.linkUrl === 'string' ? l.image.openLinkInNewTab : l.image.linkUrl.openLinkInNewTab;

            const newLink = (
              <a
                key={`${this.state.section._id}_ArticleMother_Owner_Link_${i}`}
                href={link}
                target={tab ? '_blank' : '_self'}
                rel={tab ? 'noopener noreferrer' : ''}
              >
                <i
                  className={`entypo ${l.image.icon.class}`}
                  aria-hidden="true"
                  style={{
                    fontSize: l.image.icon.size,
                    color: l.image.icon.color,
                    marginRight: '4px',
                  }}
                />
              </a>
            );

            links.push(newLink);

          }

          return null;

        });

      }

      value = (
        <div>
          {
            author.image !== ''
            && (
              <div className={styles.authorImage}>
                <SectionMedia
                  mediaType={author.image.icon ? 'ICON' : 'IMAGE'}
                  wrapperStyle="imageContent3"
                  elementStyle=""
                  iconStyle=""
                  componentIndex={undefined}
                  elementIndex={this.state.index}
                  src={author.image.CDNLink ?
                    author.image.CDNLink :
                    `${process.env.IMAGES_CDN}/${author.image.src}`}
                  alt={author.image.alt}
                  data={author.image}
                  images={this.props.images}
                  pagePathList={this.props.pagePathList}
                  articlePathList={this.props.articlePathList}
                />
              </div>
            )
          }
          {
            author.name !== ''
            && (
              <div className={styles.textCenter}>
                <h4
                  className={`${styles.Subtitle} ${styles.authorText}`}
                  style={{ ...this.state.themeHeadingStyle, ...this.state.color3 }}
                >
                  {author.name}
                </h4>
              </div>
            )
          }
          {
            author.desc !== ''
            && (
              <div className={styles.textCenter}>
                <span
                  className={`${styles.Body} ${styles.authorText}`}
                  style={this.state.themeDefaultStyle}
                >
                  { HTMLParser(author.desc) }
                </span>
              </div>
            )
          }
          {
            links.length > 0
            && (
              <div className={styles.linksWrapper}>
                { links }
              </div>
            )
          }
        </div>
      );

    }

    return value;

  }

  createSearch() {

    const value = (
      <div className={styles.searchWrapper}>
        <input
          id={`${this.state.section._id}_search`}
          type="text"
          placeholder={this.state.section.data[this.state.index].searchText}
          className={styles.searchInput}
          onKeyPress={this.handleStringSearch}
        />
        <button
          className={styles.searchBtn}
          onMouseDown={this.handleStringSearch}
        >
          <i className="entypo icon-search" />
        </button>
      </div>
    );

    return value;

  }

  createArchive(title) {

    const months = [];
    const options = [
      (
        <option
          data-value="select"
          key={`${this.state.section._id}_Archive_Select`}
        >
          { this.state.section.data[this.state.index].selectText }
        </option>
      ),
    ];

    this.state.section.data.forEach((item) => {

      if (item.type === 'ARTICLE') {

        let found = false;
        months.some((month) => {

          if (moment(month).isSame(moment(item.pubDate), 'month')) {

            found = true;

          }

          return found;

        });

        if (found === false) {

          months.push(item.pubDate);

        }

      }

    });

    months.sort((left, right) => (

      moment.utc(left).diff(moment.utc(right))

    ));


    months.forEach((month) => {

      if (moment(month).isValid()) {

        options.push(<option data-value={month} key={`${this.state.section._id}_Archive_${moment(month).format('MM YYYY')}`}>{ moment(month).format('MM YYYY') }</option>);

      }

    });

    const value = (
      <div className={styles.elementWrapper}>
        {
          title !== false
          && (
            <div className={styles.text}>
              <h3>
                { this.state.section.data[this.state.index].archiveText }
              </h3>
            </div>
          )
        }
        <select className={styles.select} onChange={this.handleDateSearch}>{ options }</select>
      </div>
    );

    return value;

  }

  createTagCloud() {

    let value;
    if (this.state.counts.length > 0) {

      value = (
        <div className={styles.elementWrapper}>
          <div className={styles.text}>
            <h3>
              { this.state.section.data[this.state.index].tagsText }
            </h3>
          </div>
          <TagCloud
            minSize={10}
            maxSize={35}
            disableRandomColor
            tags={this.state.counts}
            className="simple-cloud"
            onClick={this.handleTagSearch}
          />
        </div>
      );

    }

    return value;

  }

  createArticles() {

    const articles = [];
    const endIndex = (this.state.currentPage * this.state.articleCount) + this.state.articleCount
    > this.state.section.data.length - 1
      ? this.state.section.data.length - 1
      : (this.state.currentPage * this.state.articleCount) + this.state.articleCount;
    for (let i = this.state.currentPage * this.state.articleCount; i < endIndex; i += 1) {

      const to = this.state.section.data[i].link.target;

      let img;
      if (this.state.section.data[this.state.index].showThumbnails === true) {

        img = (
          <div key={`${this.state.section._id}_article_${i}_image_link`}>
            <Link
              to={to}
            >
              { this.createImage(this.state.section.data[i].image, i) }
            </Link>
          </div>
        );

      }

      let excerpt;
      if (this.state.section.data[this.state.index].showExcerpt === true) {

        excerpt = this.createText(this.state.section.data[i].excerpt, 'PARAGRAPH/NORMAL', i);

      }

      const pub = (
        <div key={`${this.state.section._id}_article_${i}_short_pub`}>
          <span>
            {moment(this.state.section.data[i].pubDate).format('D.M.YYYY')}
          </span>
        </div>
      );
      const title = (
        <div key={`${this.state.section._id}_article_${i}_title_link`}>
          <Link
            to={to}
          >
            { this.createText(this.state.section.data[i].title, 'TITLE', i) }
          </Link>
        </div>
      );
      const link = (
        <div key={`${this.state.section._id}_article_${i}_link`}>
          <Link
            to={to}
          >
            {this.state.section.data[i].link.text}
          </Link>
        </div>
      );
      const article = (
        <div
          key={`${this.state.section._id}_article_${i}_preview`}
          className={styles.articleWrapper}
        >
          { [img, pub, title, excerpt, link] }
          <div className={styles.line} />
        </div>

      );

      articles.push(article);

    }

    return articles;

  }

  createSearchHeader() {

    let titleText;
    let resultText;
    let field;
    if (this.state.searchResults.articles.length === 0) {

      titleText = this.state.section.data[this.state.index].noResults;

    } else if (this.state.searchResults.type === 'search') {

      titleText = this.state.section.data[this.state.index].searchResults;
      resultText = (
        <div className={styles.searchText}>
          { `${this.state.searchResults.articles.length} ${this.state.section.data[this.state.index].searchResult} "${this.state.searchResults.search}"` }
        </div>
      );
      field = (
        <div className={styles.field}>
          { this.createSearch() }
        </div>
      );

    } else if (this.state.searchResults.type === 'archive') {

      const date = moment(this.state.searchResults.search).format('MM YYYY');
      titleText = `${this.state.section.data[this.state.index].archiveText}: ${date}`;
      resultText = (
        <div className={styles.searchText}>
          { `${this.state.searchResults.articles.length} ${this.state.section.data[this.state.index].dateResult} ` }
          <span className={styles.field}>
            { date }
          </span>
        </div>
      );
      field = (
        <div className={styles.field}>
          { this.createArchive(false) }
        </div>
      );

    } else if (this.state.searchResults.type === 'tag') {

      titleText = `${this.state.section.data[this.state.index].tag}: ${this.state.searchResults.search}`;
      resultText = (
        <div className={styles.searchText}>
          { `${this.state.searchResults.articles.length} ${this.state.section.data[this.state.index].tagResult} ` }
          <span className={styles.field}>
            { this.state.searchResults.search }
          </span>
        </div>
      );

    }

    const header = (
      <React.Fragment>
        <div className={styles.searchTitle}>
          <h1>
            { titleText }
          </h1>
        </div>
        {
          this.state.searchResults.articles.length > 0
          && (
            <React.Fragment>
              <span>
                { resultText }
              </span>
              { field }
              <div className={styles.line} />
            </React.Fragment>
          )
        }
      </React.Fragment>
    );

    return header;

  }

  createSearchResults() {

    const articles = [];
    if (this.state.searchResults.articles.length > 0) {

      const endIndex = (this.state.currentPage * this.state.articleCount) + this.state.articleCount
      > this.state.searchResults.articles.length
        ? this.state.searchResults.articles.length
        : (this.state.currentPage * this.state.articleCount) + this.state.articleCount;
      for (let i = this.state.currentPage * this.state.articleCount; i < endIndex; i += 1) {

        const index = this.state.searchResults.articles[i];
        const img = this.createImage(this.state.section.data[index].image, index);
        const pub = moment(this.state.section.data[index].pubDate).format('D.M.YYYY');
        const tags = [];
        this.state.section.data[index].tags.forEach((tag) => {

          const btn = (
            <button
              style={{ color: this.props.themeData.colors[0] }}
              data-value={tag}
              className={styles.tagBtn}
              onMouseDown={this.handleTagSearch}
            >
              {tag}
            </button>
          );
          tags.push(btn);

        });
        const title = this.createText(this.state.section.data[index].title, 'TITLE', index);
        const excerpt = this.createText(this.state.section.data[index].excerpt, 'PARAGRAPH/NORMAL', index);
        const to = this.state.section.data[index].link.target;

        const link = (
          <div key={`${this.state.section._id}_article_${index}_link`}>
            <Link
              id={`${this.state.section._id}_ArticleLink_${index}`}
              to={to}
            >
              {this.state.section.data[index].link.text}
            </Link>
          </div>
        );
        const article = (
          <div
            key={`${this.state.section._id}_article_${index}_preview`}
            className={`row ${styles.articleWrapper}`}
          >
            <div className="col-12 col-sm-8">
              <div className={styles.dateTag}>
                <i className={`entypo icon-calendar ${styles.icon1}`} />
                { pub }
                <i className={`entypo icon-mouse ${styles.icon2}`} />
                <span>
                  {
                    tags.map((tag, idx) => {

                      let text = ', ';
                      if (idx === tags.length - 1) {

                        text = '';

                      }

                      return <span key={`${this.state.section._id}_${index}_TagSpan_${this.state.section.data[index].tags[idx]}`}>{tag}{text}</span>;

                    })
                  }
                </span>
              </div>
              <div>
                { title }
              </div>
              <div>
                {excerpt}
              </div>
              <div>
                {link}
              </div>
            </div>
            <div className={`col-0 col-sm-4 ${styles.resultImage}`}>
              { img }
            </div>
            <div className={`col-12 ${styles.line}`} />
          </div>

        );

        articles.push(article);

      }

    }

    return articles;

  }

  clearSearch() {

    const index = this.props.section.data.length - 1;
    const { articleCount } = this.props.section.data[index];
    const pageCount = Math.ceil((this.props.section.data.length - 1) / articleCount);

    this.setState({
      searchResults: null,
      pageCount,
      currentPage: 0,
    });

  }

  render() {

    const { sidebar } = this.state.section.data[this.state.index];
    const sidebarActive = (sidebar.authorInfo === true || sidebar.search === true
      || sidebar.archive === true || sidebar.tags === true) && this.state.searchResults === null;
    const contentCols = sidebarActive === true ? `col-12 col-sm-8 ${styles.contentPadding}` : 'col-12';
    const otherCols = sidebarActive === true ? `col-12 col-sm-4 ${styles.otherPadding}` : undefined;

    let articles;
    let searchHeader;
    if (this.state.searchResults === null) {

      articles = this.createArticles();

    } else {

      searchHeader = this.createSearchHeader();
      articles = this.createSearchResults();

    }

    let authorInfo;
    let search;
    let archive;
    let tags;
    const data = this.state.section.data[this.state.index];
    if (sidebar.authorInfo === true && this.state.searchResults === null) {

      authorInfo = this.createAuthorInfo(data);

    }

    if (sidebar.search === true && this.state.searchResults === null) {

      search = this.createSearch();

    }

    if (sidebar.archive === true && this.state.searchResults === null) {

      archive = this.createArchive();

    }

    if (sidebar.tags === true && this.state.searchResults === null) {

      tags = this.createTagCloud();

    }

    return (
      <div className="container">
        <div className="row">
          <div className={contentCols}>
            {
              this.state.searchResults !== null
              && (searchHeader)
            }
            { articles }
            {
              this.state.pageCount > 1
              && (
                <div className={styles.paginationWrapper}>
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={`${styles.pagination}`}
                    subContainerClassName="pages pagination"
                    disabledClassName={`${styles.disabledPagination}`}
                    activeClassName={`${styles.activePage}`}
                    initialPage={0}
                    disableInitialCallback
                    forcePage={this.state.currentPage}
                  />
                </div>
              )
            }
          </div>
          <div className={otherCols}>
            { authorInfo }
            { search }
            { archive }
            { tags }
          </div>
        </div>
      </div>
    );

  }

}

ArticleMotherLayout.propTypes = {
  page: PropTypes.string,
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  themeData: PropTypes.shape({
    color: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CSSModules(
  ArticleMotherLayout, styles,
  { allowMultiple: true },
);
